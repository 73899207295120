import React from 'react'

import { siteMetadata } from '../../gatsby-config'
import Layout from 'components/layout/layout'
import Meta from 'components/meta/meta'

class PageNotFound extends React.Component {
  render() {
    const { location } = this.props

    return (
      <Layout location={location} title="Page Not Found">
        <Meta site={siteMetadata} title="Page Not Found" />
        <div>
          <section className="text-center">
            <div className="container">
              <p className="h2">Page not found</p>
              <p className="lead text-muted">Sorry, we couldn't find this page.</p>
            </div>
          </section>
        </div>
      </Layout>
    )
  }
}

export default PageNotFound
